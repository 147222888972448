import { Link } from "gatsby"
import PropTypes from "prop-types"
import React,{ useLayoutEffect,useState} from "react"
import AluziLogo from "./logo"
import gsap from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";


if (typeof window !== `undefined`) {
    gsap.registerPlugin(ScrollToPlugin);
}


function Footer({  }) {
  const [isExpanded, toggleExpansion] = useState(false);
  const today = new Date();

  function scrollClick(target) {
        gsap.to(window, { duration: .5, ease: "power4.inOut", scrollTo: target });
    }

  useLayoutEffect(() => { 
    
    
    

  }, [])

  return (
    <div className="container mx-auto mt-12 md:mt-0 pt-12 md:pt-0">
      <div className="flex flex-wrap md:flex-nowrap flex-row justify-between  p-6 md:p-12">
        <div className="w-1/2 md:w-auto" onClick={() => scrollClick('#top')}>
          <AluziLogo   />
        </div>
        <div className="w-1/2  md:w-auto text-right md:text-center">
          © Aluzie {today.getFullYear()}
        </div>
        <div className="w-1/2  md:w-auto">
          Accelerate Insight
        </div>
        <div className="w-1/2  md:w-auto text-right md:text-center">
          <a className="cursor-pointer underline hover:no-underline underline-offset-2" onClick={() => scrollClick('#contact')}>Contact</a>
        </div>
      </div>
    </div>
  )
}



export default Footer
