import React, { useState }  from "react"
import axios from "axios";


const MyForm = ({}) => {
    
    const [serverState, setServerState] = useState({
      submitting: false,
      status: null
    });
    const handleServerResponse = (ok, msg, form) => {
      setServerState({
        submitting: false,
        status: { ok, msg }
      });
      if (ok) {
        form.reset();
      }
    };
    const handleOnSubmit = e => {
      e.preventDefault();
      const form = e.target;
      setServerState({ submitting: true });
      axios({
        method: "post",
        url: "https://getform.io/f/63440257-ee08-4794-9117-237a220a3053",
        data: new FormData(form)
      })
        .then(r => {
          handleServerResponse(true, "Thanks! Speak soon :)", form);
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
        });
    };
    return (
        <form onSubmit={handleOnSubmit}>
          <input className="block w-full bg-transparent my-7 px-0.5 border-0 border-b border-black placeholder:text-black focus:ring-0 focus:bg-white focus:border-aluzieBlue focus:placeholder-aluzieBlue" required type="text" name="name" placeholder="Name" />
          <input className="block w-full bg-transparent my-7 px-0.5 border-0 border-b border-black focus:ring-0 placeholder:text-black focus:bg-white focus:border-aluzieBlue focus:placeholder-aluzieBlue"  type="text" name="organisation" placeholder="Organisation" />
          <input className="block w-full bg-transparent my-7 px-0.5 border-0 border-b border-black focus:ring-0 placeholder:text-black focus:bg-white focus:border-aluzieBlue focus:placeholder-aluzieBlue" required type="email" name="email" placeholder="Email" />
          <textarea className="block w-full bg-transparent my-7 px-0.5 border-0 border-b border-black focus:ring-0 placeholder:text-black focus:bg-white focus:border-aluzieBlue focus:placeholder-aluzieBlue"  type="textarea" name="message" placeholder="Message" />

            <input className="form-input"  type="hidden" name="_gotcha" sx={{display:'none'}} />
            <div className="text-right">
              <button className="w-full md:w-auto rounded-full bg-white px-6 py-4 hover:bg-aluzieBlue"variant="secondary" type="submit">Send message</button>
            </div>
              {serverState.status && (
                <div >
                  <div className={!serverState.status.ok ? "alert alert-danger" : "alert alert-success "}  role="alert">
                      {serverState.status.msg}
                  </div>
                </div>
              )}
        </form>
    
     
    );
  };
  
  export default MyForm;