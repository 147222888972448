import React from "react";


const AluziLogo = ({}) => {
  
return (
  <svg width="122.738" height="25.426" viewBox="0 0 122.738 25.426">
                <defs>
                  <clipPath id="a"><rect width="9.227" height="5.471" fill="none" /></clipPath>
                  <clipPath id="b"><rect width="9.224" height="5.468" fill="none" /></clipPath>
                  <clipPath id="c"><rect width="9.183" height="5.353" fill="none" /></clipPath>
                  <clipPath id="d"><rect width="9.235" height="4.975" fill="none" /></clipPath>
                  <clipPath id="e"><rect width="9.413" height="5.138" fill="none" /></clipPath>
                  <clipPath id="f"><rect width="9.283" height="5.464" fill="none" /></clipPath>
                  <clipPath id="g"><rect width="8.212" height="4.882" fill="none" /></clipPath>
                  <clipPath id="h"><rect width="8.498" height="4.734" fill="none" /></clipPath>
                  <clipPath id="i"><rect width="9.405" height="5.505" fill="none" /></clipPath>
                  <clipPath id="j"><rect width="9.098" height="5.205" fill="none" /></clipPath>
                  <clipPath id="k"><rect width="9.779" height="5.397" fill="none" /></clipPath>
                  <clipPath id="l"><rect width="9.19" height="4.897" fill="none" /></clipPath>
                  <clipPath id="m"><rect width="9.472" height="5.234" fill="none" /></clipPath>
                  <clipPath id="n"><rect width="9.239" height="5.471" fill="none" /></clipPath>
                  <clipPath id="o"><rect width="9.268" height="4.99" fill="none" /></clipPath>
                </defs>
                <rect width="3.878" height="25.141" transform="translate(55.787 0.026)" fill="#262626" />
                <path d="M184.923,18.3V36.692h-3.412l-.43-2.334a6.938,6.938,0,0,1-5.675,2.549,7.1,7.1,0,0,1-5.245-2.011q-2.011-2.011-2.011-6.464V18.3h3.878v9.735a6.618,6.618,0,0,0,1.078,4.093,3.786,3.786,0,0,0,3.2,1.4,4.156,4.156,0,0,0,3.486-1.634,7.165,7.165,0,0,0,1.256-4.471V18.3h3.878Z" transform="translate(-105.862 -11.521)" fill="#262626" />
                <path d="M218.71,18.3H233.76v2.767L223.485,33.28h10.742v3.408H218.28V33.925l10.272-12.213H218.71Z" transform="translate(-137.423 -11.521)" fill="#262626" />
                <rect width="3.878" height="18.392" transform="translate(98.597 6.779)" fill="#262626" />
                <path d="M296.74,25.117a4.672,4.672,0,0,0-1.6-3.141,5.086,5.086,0,0,0-3.393-1.13,4.928,4.928,0,0,0-3.286,1.167,4.565,4.565,0,0,0-1.6,3.108h9.876Zm-12.947-2.926a8.113,8.113,0,0,1,3.16-3.3,9.164,9.164,0,0,1,4.653-1.167,9.9,9.9,0,0,1,4.686,1.078,8.021,8.021,0,0,1,3.2,3.052,9.215,9.215,0,0,1,1.2,4.634,10.581,10.581,0,0,1-.107,1.471H286.72v.215a5.736,5.736,0,0,0,1.508,3.808,4.836,4.836,0,0,0,3.626,1.4,5.187,5.187,0,0,0,3.019-.845,3.991,3.991,0,0,0,1.615-2.389h3.878a7.44,7.44,0,0,1-2.712,4.6,8.457,8.457,0,0,1-5.512,1.8,10.307,10.307,0,0,1-5.012-1.167,8.157,8.157,0,0,1-3.3-3.286,10.041,10.041,0,0,1-1.167-4.919,10.581,10.581,0,0,1,1.13-4.975" transform="translate(-177.954 -11.156)" fill="#262626" />
                <path d="M269.418.07c-.5,1.378-.752,2.078-1.23,3.408-1.441-.448-2.152-.7-3.578-1.182" transform="translate(-166.591 -0.044)" fill="#fff" /><g transform="translate(25.467 8.335)">
                  <g clip-path="url(#a)">
                    <path d="M79.5,27.09c-.522,1.445-.789,2.186-1.274,3.526-1.426-.485-2.137-.737-3.578-1.182" transform="translate(-72.464 -25.39)" fill="#fff" />
                  </g>
                </g>
                <g transform="translate(33.854 7.153)">
                  <g clip-path="url(#b)">
                    <path d="M102.139,23.9c-.485,1.341-.733,2.182-1.219,3.523a28.074,28.074,0,0,1-3.63-1.037" transform="translate(-95.104 -22.2)" fill="#fff" />
                  </g>
                </g>
                <g transform="translate(29.045 9.639)">
                  <g clip-path="url(#c)">
                    <path d="M89.118,30.61c-.5,1.378-.752,2.078-1.23,3.408-1.441-.448-2.152-.7-3.578-1.182" transform="translate(-82.124 -28.91)" fill="#fff" />
                  </g>
                </g>
                <g transform="translate(32.62 10.676)">
                  <g clip-path="url(#d)">
                    <path d="M98.824,33.42c-.485,1.341-.641,1.689-1.126,3.03a10.511,10.511,0,0,1-3.738-.663" transform="translate(-91.774 -31.716)" fill="#fff" />
                  </g>
                </g>
                <g transform="translate(27.582 13.047)">
                  <g clip-path="url(#e)">
                    <path d="M85.408,39.81c-.482,1.33-.859,2.323-1.174,3.193-1.5-.267-2.352-.207-3.864-.456" transform="translate(-78.181 -38.11)" fill="#fff" />
                  </g>
                </g>
                <g transform="translate(31.449 13.71)">
                  <g clip-path="url(#f)">
                    <path d="M95.712,41.6c-.485,1.341-.993,2.83-1.245,3.519-1.471,0-2.16-.722-3.667-.989" transform="translate(-88.614 -39.9)" fill="#fff" />
                  </g>
                </g>
                <g transform="translate(24.341 4.212)">
                  <g clip-path="url(#g)">
                    <path d="M75.451,15.97c-.385,1.067-.619,1.708-1.063,2.938a8.372,8.372,0,0,1-2.778-1.122" transform="translate(-69.424 -14.266)" fill="#fff" />
                  </g>
                </g>
                <g transform="translate(16.921 16.069)">
                  <g clip-path="url(#h)">
                    <path d="M55.7,47.97c-.4,1.111-.83,1.5-1.293,2.789-1.289-.185-1.652-.693-2.83-.952" transform="translate(-49.394 -46.27)" fill="#fff" />
                  </g>
                </g>
                <g transform="translate(22.533 15.784)">
                  <g clip-path="url(#i)">
                    <path d="M71.77,47.2c-.422,1.174-.715,2.223-1.2,3.56-1.6,0-2.26-.437-3.834-.522" transform="translate(-64.551 -45.5)" fill="#fff" />
                  </g>
                </g>
                <g transform="translate(30.394 17.229)">
                  <g clip-path="url(#j)">
                    <path d="M92.673,51.1c-.248.689-.73,1.926-1.211,3.26a6.167,6.167,0,0,0-3.512-.908" transform="translate(-85.764 -49.4)" fill="#fff" />
                  </g>
                </g>
                <g transform="translate(24.989 19.577)">
                  <g clip-path="url(#k)">
                    <path d="M78.771,57.45c-.47,1.3-.985,2.315-1.3,3.2-1.611.026-2.445.115-4.1.259" transform="translate(-71.181 -55.746)" fill="#fff" />
                  </g>
                </g>
                <g transform="translate(29.09 20.488)">
                  <g clip-path="url(#l)">
                    <path d="M89.252,59.9c-.482,1.334-.759,2.178-1.037,2.956a13.36,13.36,0,0,0-3.775-.667" transform="translate(-82.251 -58.2)" fill="#fff" />
                  </g>
                </g>
                <g transform="translate(16.566 20.192)">
                  <g clip-path="url(#m)">
                    <path d="M55.727,59.11c-.445,1.234-.659,1.83-1.159,3.2a11.269,11.269,0,0,1-3.938-.182" transform="translate(-48.441 -57.406)" fill="#fff" />
                  </g>
                </g>
                <g transform="translate(8.964 18.429)">
                  <g clip-path="url(#n)">
                    <path d="M34.974,54.35c-.522,1.441-.789,2.178-1.274,3.526a18.593,18.593,0,0,0-3.589-1.148" transform="translate(-27.921 -52.646)" fill="#fff" />
                  </g>
                </g>
                <g transform="translate(0 17.488)">
                  <g clip-path="url(#o)">
                    <path d="M10.8,51.81c-.4,1.1-.6,1.648-.974,2.7-1.6.174-2.426.282-3.915.348" transform="translate(-3.721 -50.106)" fill="#fff" />
                  </g>
                </g>
                <path d="M108.491,0h-4.919L100.66,7.9a10.815,10.815,0,0,0,3.589.952l1.656-4.4,4.023,10.954h-4.66l-4.593,3.519h10.4l2.371,6.212h4.419Z" transform="translate(-63.373)" fill="#262626" />
    </svg> 
  );
  



};

export default AluziLogo;