import React,{ createRef,useRef,useLayoutEffect,useEffect} from "react"
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Header from "../components/header";
import Footer from "../components/footer";
import FormBlock from '../components/contact_block'

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
  gsap.registerPlugin(ScrollToPlugin);
  
}

const IndexPage = ({ }) => { 

  const intro = useRef(null);



    function scrollClick(target) {
        gsap.to(window, { duration: .5, ease: "power4.inOut", scrollTo: target });
    }
  



  useLayoutEffect(() => { 

    const setContainerHeight = () => {
      const windowHeight = window.innerHeight;
      document.documentElement.style.setProperty('--vh', `${windowHeight}px`);
    };
    window.addEventListener('resize', setContainerHeight);

    setContainerHeight();



       let letintroGradWipeTL = gsap.timeline({});
      letintroGradWipeTL
      .fromTo('.pinwipe .gradwipe',
        { y:'-100vh',opacity:0  },
        {  y:0,opacity: 1, duration: 2 })
      .fromTo('.pinwipe .introtext span',
        { filter:"blur(10px)",opacity:0  },
        { filter: "blur(0px)", stagger: 0.2, opacity: 1, duration: 2 },1)
        .to('.chevron_down', { y: '50px', opacity: 1, duration: 1 })
      .to('.chevron_down a ', { y: '15px',opacity:1,transformOrigin:"center center", ease: 'Sine.easeIn',  repeat: -1, yoyo: true},6)
      

      let gradwipeTL = gsap.timeline({
        // yes, we can add it to an entire timeline!
        scrollTrigger: {
            trigger: ".pinwipe ",
            anticipatePin: 1,
            //markers:true,
            scrub: 1,
            pin:true,
        }
      });
      gradwipeTL
        .to('.pinwipe .introtext ',   { y:'-50vh',opacity:0,duration: 1 },0)
        .fromTo('.pinwipe .gradwipe',
          { y:0,  },
          { y: '-50vh', duration: 1 }, 0.3)
        .fromTo('.pinwipe .reveal .unknown',
          { transformOrigin:"center",opacity:0,scale:0.9, filter:"blur(3px)"  },
          { opacity: 1,scale:1, filter: "blur(0px)", duration: 1 }, 0.4)
        .to('header', { y: '-120px', opacity: 0, duration: 1 }, 0)
      .to('.chevron_down svg', { y:'-100px', opacity: 0, duration: 1, filter:"blur(3px)"  },0)
        
      
      
      let footerWipeTL = gsap.timeline({
        // yes, we can add it to an entire timeline!
        scrollTrigger: {
          trigger: "footer",
          start: "top bottom",
            end: "bottom bottom",
            anticipatePin: 1,
            scrub: 1,
        }
      });
      footerWipeTL
        .fromTo('.gradwipeBottom',
          { opacity:0,  },
          { opacity: 1, duration: 1 })
        .to('.chevron_up', { y: '-100px', opacity: 1, duration: 1 })
    
    
    
  ScrollTrigger.matchMedia({

      //////////////////////////////////////////////////////////////////////////////////////////////////
      "(min-width: 769px)": function () {
        //desktop
      var section_headers = gsap.utils.toArray('section .westside ');
      var sections = gsap.utils.toArray('section ');
      var sectionTriggers = gsap.utils.toArray('section .trigger');
     
        

      
      //pin section_headers elements
      section_headers.forEach((sh, i) => {
          ScrollTrigger.create({
            trigger: sh,
            anticipatePin: 1,
            pin:sh.querySelector('.pin'),
            start: "top top",
            end: "bottom bottom",
          });
      })

      var borderPin = gsap.utils.toArray('section .eastside ');

      //pin section_headers elements
      borderPin.forEach((bp, i) => {
          ScrollTrigger.create({
            trigger: bp,
            anticipatePin: 1,
            pin:bp.querySelector('.bp'),
            start: "top top",
            end: "bottom bottom",
          });
      })





      

      
      //pin section 1 elements
      sectionTriggers.forEach((trigger, i) => {
          ScrollTrigger.create({
            trigger: trigger,
            anticipatePin: 1,
            pin:trigger.querySelector('.pin'),
            start: "top top",
            end: "bottom bottom",
          });
      })

      //fade section 1 elements
      sectionTriggers.forEach((trigger, i) => {
        const transitionOne = gsap.fromTo(trigger.querySelectorAll('p'), { opacity: 0,y:100 }, { opacity: 1,y:0,stagger:0.1 });
        ScrollTrigger.create({
          scrub: 1,
          anticipatePin: 1,
            trigger: trigger,
            start: "top bottom",
            end: "bottom bottom",
            animation: transitionOne,
          });
      })
       
        
      },

      //////////////////////////////////////////////////////////////////////////////////////////////////
      "(max-width: 769px)": function () { 
   






      }

 
    });







    


    

    

    

 
    return () => {
      window.removeEventListener('resize', setContainerHeight);
      
    };


    }, [])


  
 
  return (
  <Layout>
      <SEO title="Tomorrow’s Coding Clarity | Aluzie" />
      <div className="hidden text-black text-white text-mmyellow text-mmpurple bg-black bg-white bg-mmyellow bg-mmpurple fill-black fill-white fill-mmyellow fill-mmpurple"></div>
      <Header/>
      <div className=" relative pinwipe min-h-screen">
        <div className="chevron_down cursor-pointer opacity-0 fixed bottom-40 w-full flex flex-col pl-6 md:pl-0 md:items-center z-50" onClick={() => scrollClick(700)}>
          <a className="p6">
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="16" viewBox="0 0 36 16" className="fill-aluzieBlue ">
            <g><path d="M18,0,36,16H0Z" transform="translate(36 16) rotate(180)" />
              </g>
            </svg>
            </a>
           
        </div>
        <div className="gradwipe opacity-0 h-screen bg bg-gradient-to-b from-aluzieBlue via-aluzieBlueLight to-transparent absolute top-0 left-0 z-10 w-screen"></div>
        
        <div class="intro absolute top-0 left-0 w-screen z-20">
          <section className="container mx-auto">
                <div className="h-screen trigger1 flex items-center flex-1 p-5">
                  <div className="introtext" >
                    <p className="text-sectiontitle pt-5"><span className="opacity-0 inline-block p-2">Tomorrow’s</span><span className="opacity-0 inline-block p-2">Coding</span><span className="opacity-0 inline-block p-2">Clarity</span> </p>
                  </div>
              </div>
          </section>
        </div>

        <div class="reveal container mx-auto ">
          <section className="flex section0"  >
                <div className="h-screen trigger1 flex items-center flex-1 p-6 md:p-12">
                <div className="pin unknown  opacity-0">
                  <p className="text-sectiontitleMobile md:text-sectiontitle pt-5">Stop working with unknown unknowns </p>
                </div>
            </div>
          </section>
        </div>
      </div>
             

      <div className="sections z-10">
        <div class="container mx-auto">
          <section className="flex flex-col md:flex-row section1 ">
            <div className="flex  westside  w-full md:w-2/5 2xl:w-1/2" id="why">
                <div className="md:h-150 trigger1 flex-1 p-6  md:p-12">
                  <div className="pin">
                    <p className="text-sectiontitleMobile md:text-sectiontitle border-t border-black pt-5">The Complex Landscape of Software Engineering </p>
                  </div>
                </div>
            </div>
            <div className="flex flex-col  flex-1 eastside ">
                <div className="md:h-screen px-6 pt-0 md:p-12">
                  <p className="text-2xl text-black/10 bp border-t border-black "></p>
                </div>
              <div className="md:h-150 trigger p-6  md:p-12 ">
                <div className="pin">
                  <p className="text-titleMobile md:text-title pt-3 md:pt-7 font-medium md:font-normal">Three decades have passed.</p>
                </div>
              </div>
              <div className="md:h-150 trigger p-6 pt-0 md:p-12 ">
                <div className="pin">
                  <p className="text-titleMobile md:text-title pt-3 md:pt-7 font-medium md:font-normal">Software engineering has evolved.</p>
                  </div>
              </div>
              <div className="md:h-150 trigger p-6 pt-0 md:p-12 ">
                <div className="pin">
                  <p className="text-titleMobile md:text-title pt-3 md:pt-7 font-medium md:font-normal">Methodologies, maintenance and updates have all advanced. </p>
                  </div>
              </div>
              <div className="md:h-150 trigger p-6 pt-0 md:p-12 ">
                <div className="pin">
                  <p className="text-l md:text-title pt-3 md:pt-7">Yet, software programmers are still spending 50-70% of their time trying to understand programs. The complexity of understanding the source of these codes is laborious, manual and costly, but it remains the industry standard, why?</p>
                  <p className="text-l 2xl:text-xl pt-3 md:pt-7">Until now, nothing existed to represent the data in a simple, efficient way. Sure, there were tools that mined data and fed back insights. But it was fragmented. A targeted piece of code here, an abstract string of numbers there. None of it really gave the bigger picture view. Or the insights needed to be valuable and accurate enough to call it credible, useful or trustworthy. </p>
                  <p className="text-l 2xl:text-xl pt-3 md:pt-7">Data is critical. And incorrect data is high-risk, sometimes even fatal. Especially in the financial and healthcare industries. The pattern of errors needed to stop. Both to safeguard people, processes and to make the lives of software testers, programmers and engineers easier and more insightful, everywhere.</p>
                  <p className="text-lg md:text-2xl pt-3 md:pt-7 font-medium">Eventually, someone asked: <br/>‘Can’t we just automate that?’<br/>So, we did.</p>  
                </div>
              </div>
            </div>
          </section>

          <section className="flex flex-col md:flex-row  section2" id="what">
            <div className="flex  westside w-full md:w-2/5 2xl:w-1/2">
              <div className="md:h-150 trigger1 flex-1 p-6 pt-12 md:p-12">
                  <div className="pin">
                    <p className="text-sectiontitleMobile md:text-sectiontitle border-t border-black pt-5">Accelerating Insight for Tomorrow’s Coding Clarity</p>
                  </div>
                </div>
            </div>
              <div className="flex flex-col  flex-1 eastside">
                <div className="md:h-screen px-6 pt-0 md:p-12">
                  <p className="text-2xl text-black/10 bp border-t border-black "></p>
                </div>
                
              <div className="md:h-screen trigger p-6 md:p-12">
                <p className="text-titleMobile md:text-title pt-3 md:pt-7 font-medium md:font-normal">Aluzie believes there’s a better way. <br/>A faster way. <br/>A smarter way. </p>
                <p className="text-l 2xl:text-2xl pt-3 md:pt-7">A way to understand code, without needing to understand code. And a way to stop working with the unknown unknowns – changing code without knowing the real impact. It’s 2023. We’re better than guesswork.</p>
                <p className="text-l 2xl:text-xl pt-3 md:pt-7">In partnership with SETsquared and Surrey University Research Park, we have created a tool that uniquely analyses software codes, representing it back to the end user, helping them to make smarter decisions through in-depth insights. </p></div>
            </div>
          </section>

          <section className="flex flex-col md:flex-row  section3">
            <div className="flex   westside w-full md:w-2/5 2xl:w-1/2">
                <div className="md:h-150 trigger1 flex-1 p-6 pt-12 md:p-12">
                  <div className="pin">
                    <p className="text-sectiontitleMobile md:text-sectiontitle  border-t border-black pt-5">Inspired by Knowledge Engineering</p>
                  </div>
                </div>
            </div>
              <div className="flex flex-col  flex-1 eastside">
                <div className="md:h-screen px-6 pt-0 md:p-12">
                  <p className="text-2xl text-black/10 bp border-t border-black "></p>
                </div>

              <div className="md:h-screen trigger p-6 md:p-12">
                  <p className="text-titleMobile md:text-title pt-3 md:pt-7 font-medium md:font-normal">Aluzie is a future-forward software coding tool, inspired by Knowledge Engineering</p>
                  <p className="text-l 2xl:text-2xl pt-3 md:pt-7">This methodology helped shape our thoughts and align all the technical, scientific and social aspects involved in developing our tool, to ensure its longevity and usability in the field. It also helped and continues to help us join the dots in the process of understanding and representing human knowledge in data structures.</p>
                  <p className="text-l 2xl:text-xl pt-3 md:pt-7">The sole aim of Aluzie is to boost human productivity and efficiency. As the tool evolves, we hope to use the same methodology of Knowledge Engineering to integrate machine learning and pivot towards a dual model of operational excellence, involving humans and AI.</p>
                  <p className="text-l 2xl:text-xl pt-3 md:pt-7">It’s an open door… watch this space for upcoming developments.</p>
                </div>
              </div>
              
            </section>
            
          <section className="flex flex-col md:flex-row  section4 " id="how">
            <div className="flex  westside w-full md:w-2/5 2xl:w-1/2">
                <div className="md:h-150 trigger1 flex-1 p-6 pt-12 md:p-12">
                  <div className="pin">
                    <p className="text-sectiontitleMobile md:text-sectiontitle  border-t border-black pt-5">The big questions </p>
                  </div>
                </div>
            </div>
            <div className="flex flex-col  flex-1 eastside">
                <div className="md:h-screen px-6 pt-0 md:p-12">
                  <p className="text-2xl text-black/10 bp border-t border-black "></p>
                </div>
              <div className="md:h-150 trigger p-6 md:p-12">
                <div className="pin">
                    <p className="text-titleMobile md:text-title pt-3 md:pt-7 font-medium md:font-normal">What is Aluzie?</p>
                    <p className="text-l 2xl:text-2xl pt-3 md:pt-7">Aluzie removes the guesswork from software coding. It’s an intuitive, advanced piece of software that dissects and analyses data, before feeding back a precise representation of how it works and flows.  </p>
                    <p className="text-l 2xl:text-xl pt-3 md:pt-7">With that insight, programmers, engineers and testers gain the flexibility and control they need to modify and improve the software, with maximum precision and accuracy.</p>
                </div>
              </div>
              <div className="md:h-150 trigger p-6 md:p-12">
                <div className="pin">
                    <p className="text-titleMobile md:text-title pt-3 md:pt-7 font-medium md:font-normal">What does it mean?</p>
                    <p className="text-l 2xl:text-2xl pt-3 md:pt-7">Aluzie is roman for ‘allusion’ or ‘to hint’.  </p>
                    <p className="text-l 2xl:text-xl pt-3 md:pt-7">Why name our software tool this? Great question. It goes back to the basic origins of our company – we were fed up with the lack of progress being made in our industry, when it came to solving complex coding issues. So many programmers, engineers and testers were always looking for a hint or a clue – that golden thread that tied it all together and made sense of the software they were trying to comprehend.</p>
                    <p className="text-l 2xl:text-xl pt-3 md:pt-7">Aluzie is the answer. The simple, efficient solution that enables them to join the dots, without getting into the deep complexity of it all. Aluzie is the hint the industry has been searching for. </p>
                  </div>
              </div>
              <div className="md:h-150 trigger p-6 md:p-12">
                <div className="pin">
                    <p className="text-titleMobile md:text-title pt-3 md:pt-7 font-medium md:font-normal">Who is it for? </p>
                    <p className="text-l 2xl:text-2xl pt-3 md:pt-7">Aluzie was developed to be used by software engineers, programmers and testers.</p>
                  </div>
                </div>
              <div className="md:h-150 trigger p-6 md:p-12">
                <div className="pin">
                    <p className="text-titleMobile md:text-title pt-3 md:pt-7 font-medium md:font-normal">What problem does it solve? </p>
                    <p className="text-l 2xl:text-2xl pt-3 md:pt-7">Aluzie solves the problem of obscurity, delivering vast understanding without vagueness. Like all products within the software development services market, Aluzie focuses on increasing developer output productivity and quality.</p>
                    <p className="text-l 2xl:text-xl pt-3 md:pt-7">Although unlike others, our software tool doesn’t just mine data, information and knowledge. It feeds back a precise representation of the software code – delivering greater understanding and insights, for smarter, faster decision making and less room for errors.</p>
                  </div>
                </div>
                <div className="md:h-150 trigger p-6 md:p-12">
                <div className="pin">
                    <p className="text-titleMobile md:text-title pt-3 md:pt-7 font-medium md:font-normal">Why now? </p>
                    <p className="text-l 2xl:text-2xl pt-3 md:pt-7">Decades of academic software engineering research has led us to this point. Tomorrow’s programmers, engineers and testers need us today. That’s why Aluzie exists – to plug a gap in the industry that’s been needed for the last 30 years.</p>
                    <p className="text-l 2xl:text-xl pt-3 md:pt-7">With the support of SETsquared and Surrey University Research Park, we’re already progressing fast, and will be adding some of our most recent case studies soon.</p>
                  </div>
              </div>
            
            </div>
          </section>
          
          <footer>
            <section className="flex flex-col md:flex-row  section4 " >
            <div className="flex  westside w-full md:w-2/5 2xl:w-1/2">
                <div className="md:h-150 trigger1 flex-1 p-6 pt-12 md:p-12">
                  <div className="pin">
                    <p className="text-sectiontitleMobile md:text-sectiontitle  border-t border-black pt-5">Where can I find out more?</p>
                  </div>
                </div>
            </div>
            <div className="flex flex-col  flex-1 eastside" >
                <div className="md:h-screen p-6 pt-0 md:p-12">
                  <p className="text-2xl text-black/10 bp border-t border-black "></p>
                </div>
              <div className="md:h-150 trigger px-6 md:p-12">
                <div className="pin">
                    <p className="text-l md:text-title pt-0 md:pt-7" id="contact">Are you a software programmer, engineer or tester? Investor or tech writer? We’re on a mission to drive awareness and build our brand further.</p>
                    <p className="text-l 2xl:text-2xl pt-3 md:pt-7">For more information about the company and how you can become involved in developing our aspirational innovations, please fill in the form below.</p>
                    <p className="text-l 2xl:text-xl pt-3 md:pt-7 font-medium md:font-normal">We can’t wait to hear from you. </p>
                    <p><FormBlock /></p>
                </div>
              </div>

      
              
            
            </div>
            </section>
            <Footer/>
            </footer>
        </div>
      </div>
      <div className="chevron_up opacity-0 fixed bottom-4 md:bottom-0  z-30 w-full pointer-events-none flex flex-col items-end px-6 md:items-center">
        <a className="pointer-events-auto flex-1 text-center " onClick={() => scrollClick('#top')}>
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="16" viewBox="0 0 36 16" className="fill-white "><path d="M18,0,36,16H0Z" transform="translate(0 0) rotate(0)" /></svg>
          <span>Top</span>
        </a>
      </div>
       <div className="opacity-0 gradwipeBottom h-screen bg bg-gradient-to-t from-aluzieGreen via-aluzieGreenLight to-transparent fixed top-0 left-0 -z-10 w-screen "></div>
    </Layout>
      )  
}


export default IndexPage
