import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import AluziLogo from "./logo"
import gsap from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";


if (typeof window !== `undefined`) {
    gsap.registerPlugin(ScrollToPlugin);
}


function Header({  }) {
  const [isExpanded, toggleExpansion] = useState(false)

   function scrollClick(target) {
        gsap.to(window, { duration: .5, ease: "power4.inOut", scrollTo: target });
    }

  return (
    <header className="  fixed z-30 w-screen">
        <div className="container mx-auto">
          <div className="flex justify-between  p-6 md:p-12">
            <div>
             <AluziLogo />
</div> <a className="cursor-pointer underline hover:no-underline underline-offset-2" onClick={() => scrollClick('#contact')}>Contact</a>
          </div>
        </div>
      </header>
  )
}



export default Header
